// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/mailchimp.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.MailchimpCampaign
 */
export const MailchimpCampaign = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MailchimpCampaign",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "recipient_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateMailchimpCampaignRequest
 */
export const CreateMailchimpCampaignRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateMailchimpCampaignRequest",
  () => [
    { no: 1, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.CreateMailchimpCampaignResponse
 */
export const CreateMailchimpCampaignResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.CreateMailchimpCampaignResponse",
  () => [
    { no: 1, name: "campaign", kind: "message", T: MailchimpCampaign },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.MailchimpStaticSegment
 */
export const MailchimpStaticSegment = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.MailchimpStaticSegment",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMailchimpStaticSegmentsRequest
 */
export const ListMailchimpStaticSegmentsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMailchimpStaticSegmentsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListMailchimpStaticSegmentsResponse
 */
export const ListMailchimpStaticSegmentsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListMailchimpStaticSegmentsResponse",
  () => [
    { no: 1, name: "static_segments", kind: "message", T: MailchimpStaticSegment, repeated: true },
  ],
);

