/* eslint-disable @typescript-eslint/naming-convention */
import type {
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
} from 'react';
import { useRef, useState } from 'react';
import type {
  HookReturn as PlacesHookReturn,
  Suggestion,
} from 'use-places-autocomplete';
import type { InputProps, SystemStyleObject } from '@chakra-ui/react';
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  chakra,
  useBoolean,
  useMultiStyleConfig,
  useOutsideClick,
} from '@chakra-ui/react';
import type { ThemeObject } from '@cuebox-types/chakra';
import useOutsideFocus from '@hooks/useOutsideFocus';
import getAriaAttributes from './utils/getAriaAttributes';

const attributionStyles: SystemStyleObject = {
  _after: {
    content: "''",
    display: 'block',
    marginRight: 2,
    marginTop: 2,
    height: '18px',
    boxSizing: 'border-box',
    backgroundImage: 'url(/assets/powered-by-google.png)',
    backgroundPosition: 'right',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '120px 14px',
  },
};

type SelectedIndex = number | null;

interface GooglePlacesAutocompleteProps
  extends Omit<PlacesHookReturn, 'clearCache' | 'init'>,
    Omit<InputProps, 'value'> {
  shouldShowAttribution?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onPlaceSelect: (place: Suggestion) => void | Promise<void>;
}

const GooglePlacesAutocomplete = ({
  shouldShowAttribution = true,
  ready: isReady,
  value,
  suggestions: { status, data, loading: isLoading },
  setValue,
  clearSuggestions,
  onFocus,
  onBlur,
  onChange,
  onPlaceSelect,
  isDisabled,
  ...inputProps
}: GooglePlacesAutocompleteProps) => {
  const menuStyles: Record<string, ThemeObject> = useMultiStyleConfig('Menu');

  const [currIndex, setCurrIndex] = useState<SelectedIndex>(null);
  const hasCurrIndex = typeof currIndex === 'number';

  const hasSuggestions = status === 'OK';

  const [isOpen, setIsOpen] = useBoolean(false);
  const realIsOpen: boolean = isOpen && hasSuggestions && !!value;

  const wrapperRef = useRef<HTMLDivElement>(null);

  // Close the suggestions menu when the user clicks outside of it
  // or if the user tabs away from the input
  const useOutsideParams = { ref: wrapperRef, handler: setIsOpen.off };
  useOutsideClick(useOutsideParams);
  useOutsideFocus(useOutsideParams);

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (onChange) {
      onChange(e);
    }
    setValue(e.target.value);
  };

  const handleSelect = (placeSuggestion: Suggestion) => {
    return async () => {
      setIsOpen.off();
      setCurrIndex(null);
      await onPlaceSelect(placeSuggestion);
      clearSuggestions();
    };
  };

  const handleMouseEnter = (idx: number) => () => {
    setCurrIndex(idx);
  };

  const handleMouseLeave = () => {
    setCurrIndex(null);
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onFocus) {
      onFocus(e);
    }
    if (isReady && value) {
      setIsOpen.on();
    }
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    let shouldPreventDefault = true;

    switch (e.key) {
      case 'Enter':
        if (hasCurrIndex && data[currIndex]) {
          handleSelect(data[currIndex])();
        } else {
          shouldPreventDefault = false;
        }
        break;

      case 'Escape':
        setCurrIndex(null);
        setIsOpen.off();
        break;

      case 'ArrowUp':
        if (hasSuggestions && isOpen) {
          let nextIndex: SelectedIndex = currIndex ?? data.length;
          nextIndex = nextIndex && nextIndex > 0 ? nextIndex - 1 : null;
          setCurrIndex(nextIndex);
        } else {
          shouldPreventDefault = false;
        }
        break;

      case 'ArrowDown':
        if (hasSuggestions) {
          if (!isOpen) {
            setIsOpen.on();
          }
          let nextIndex: SelectedIndex = currIndex ?? -1;
          nextIndex = nextIndex < data.length - 1 ? nextIndex + 1 : null;
          setCurrIndex(nextIndex);
        } else if (value) {
          setIsOpen.on();
          setValue(value, true);
          setCurrIndex(0);
        } else {
          shouldPreventDefault = false;
        }
        break;

      default:
        shouldPreventDefault = false;
        break;
    }

    if (shouldPreventDefault) {
      e.preventDefault();
    }
  };

  const handleKeyUp: KeyboardEventHandler = (e) => {
    // These keys are already handled by the `onKeyDown` handler
    const actionKeys = ['Enter', 'Escape', 'ArrowUp', 'ArrowDown'];

    const { key } = e;

    if (!actionKeys.includes(key) && value) {
      // For some reason the keyboard events are triggered when an autofill option is selected
      // with the `key` property set to "Unidentified", so we can use that to know to close the popover
      if (key === 'Unidentified') {
        setIsOpen.off();
      } else {
        setIsOpen.on();
      }
    }
  };

  const { inputAttributes, listboxAttributes, getOptionAttributes } =
    getAriaAttributes(realIsOpen, currIndex, {
      listboxId: 'google-places-autocomplete-listbox',
      optionIdPrefix: 'google-places-autocomplete-option',
    });

  return (
    <Box ref={wrapperRef}>
      <Popover isOpen={realIsOpen} matchWidth autoFocus={false} isLazy>
        <PopoverTrigger>
          <InputGroup>
            <Input
              {...inputProps}
              value={value}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onKeyUp={handleKeyUp}
              onFocus={handleFocus}
              onBlur={handleBlur}
              isDisabled={!isReady ? true : isDisabled}
              {...inputAttributes}
            />

            {isLoading && realIsOpen && (
              <InputRightElement>
                <Spinner size="sm" />
              </InputRightElement>
            )}
          </InputGroup>
        </PopoverTrigger>

        <PopoverContent w="auto">
          <PopoverBody p={0}>
            <chakra.div
              onMouseLeave={handleMouseLeave}
              {...listboxAttributes}
              sx={{
                ...menuStyles.list,
                m: 0,
                borderWidth: 0,
                shadow: 'none',
                w: '100%',
                ...(shouldShowAttribution ? attributionStyles : {}),
              }}
            >
              {data.map((suggestion: Suggestion, suggestionIndex: number) => {
                const {
                  place_id,
                  structured_formatting: { main_text, secondary_text },
                } = suggestion;

                return (
                  <chakra.div
                    key={place_id}
                    onClick={handleSelect(suggestion)}
                    onMouseEnter={handleMouseEnter(suggestionIndex)}
                    data-focus={
                      suggestionIndex === currIndex ? true : undefined
                    }
                    {...getOptionAttributes(suggestionIndex)}
                    sx={{
                      ...menuStyles.item,
                      _hover: menuStyles.item._focus,
                      cursor: 'pointer',
                    }}
                  >
                    <chakra.strong>{main_text}</chakra.strong>{' '}
                    <chakra.span>{secondary_text}</chakra.span>
                  </chakra.div>
                );
              })}
            </chakra.div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default GooglePlacesAutocomplete;
