// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/checkout.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Address, FinixPaymentInstrumentType, Money, PhoneNumber } from "../../../common/v1/common_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.ContactInfo
 */
export const ContactInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ContactInfo",
  () => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email_marketing_opt_in", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "recognition_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.BillingInfo
 */
export const BillingInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.BillingInfo",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "phone_number", kind: "message", T: PhoneNumber },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ShippingInfo
 */
export const ShippingInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ShippingInfo",
  () => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "phone_number", kind: "message", T: PhoneNumber },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.PaymentInfo
 */
export const PaymentInfo = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.PaymentInfo",
  () => [
    { no: 1, name: "tilled_payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "finix_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "finix_fraud_session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "finix_billing_postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "finix_third_party_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "finix_payment_instrument_type", kind: "enum", T: proto3.getEnumType(FinixPaymentInstrumentType) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CheckoutCartRequest
 */
export const CheckoutCartRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CheckoutCartRequest",
  () => [
    { no: 1, name: "cart_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "contact_info", kind: "message", T: ContactInfo },
    { no: 3, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 4, name: "shipping_info", kind: "message", T: ShippingInfo },
    { no: 5, name: "payment_info", kind: "message", T: PaymentInfo },
    { no: 6, name: "total", kind: "message", T: Money },
    { no: 7, name: "cf_turnstile_response", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CheckoutCartResponse
 */
export const CheckoutCartResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CheckoutCartResponse",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CreateApplePaySessionRequest
 */
export const CreateApplePaySessionRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CreateApplePaySessionRequest",
  () => [
    { no: 1, name: "validation_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.CreateApplePaySessionResponse
 */
export const CreateApplePaySessionResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.CreateApplePaySessionResponse",
  () => [
    { no: 1, name: "session_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "session_details", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

