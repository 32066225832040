// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/consumer/v1/order.proto (package cuebox.services.consumer.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Asset, File, FulfillmentStatus, Money, OrderItemPaymentStatus, OrderPaymentStatus, PaymentMethod, RevenueType, SalesChannel, SubscriptionFrequency, TicketBookedStatus, TicketDeliveryMethod, TicketPrintStatus } from "../../../common/v1/common_pb.js";
import { Show, Showtime } from "./show_pb.js";
import { PriceLevel, TicketType } from "./price_list_view_pb.js";
import { Seat } from "./cart_pb.js";
import { Membership } from "./membership_pb.js";
import { Merchandise } from "./merchandise_pb.js";
import { BillingInfo, ShippingInfo } from "./checkout_pb.js";

/**
 * @generated from message cuebox.services.consumer.v1.DonationOrderItem
 */
export const DonationOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.DonationOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 5, name: "donation_at", kind: "message", T: Timestamp },
    { no: 6, name: "frequency", kind: "enum", T: proto3.getEnumType(SubscriptionFrequency) },
    { no: 7, name: "subtotal", kind: "message", T: Money },
    { no: 8, name: "discount", kind: "message", T: Money },
    { no: 9, name: "fee", kind: "message", T: Money },
    { no: 10, name: "refund", kind: "message", T: Money },
    { no: 11, name: "net", kind: "message", T: Money },
    { no: 12, name: "total", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.TicketOrderItem
 */
export const TicketOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.TicketOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "show", kind: "message", T: Show },
    { no: 5, name: "showtime", kind: "message", T: Showtime },
    { no: 6, name: "ticket_type", kind: "message", T: TicketType },
    { no: 7, name: "price_level", kind: "message", T: PriceLevel },
    { no: 8, name: "ticket_delivery_method", kind: "enum", T: proto3.getEnumType(TicketDeliveryMethod) },
    { no: 9, name: "booked_status", kind: "enum", T: proto3.getEnumType(TicketBookedStatus) },
    { no: 10, name: "subtotal", kind: "message", T: Money },
    { no: 11, name: "discount", kind: "message", T: Money },
    { no: 12, name: "fee", kind: "message", T: Money },
    { no: 13, name: "refund", kind: "message", T: Money },
    { no: 14, name: "net", kind: "message", T: Money },
    { no: 15, name: "total", kind: "message", T: Money },
    { no: 16, name: "seat", kind: "message", T: Seat },
    { no: 17, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 18, name: "season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "checked_in_at", kind: "message", T: Timestamp },
    { no: 20, name: "google_wallet_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SeasonPackageOrderItemStat
 */
export const SeasonPackageOrderItemStat = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackageOrderItemStat",
  () => [
    { no: 1, name: "season_package_item_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_types", kind: "message", T: TicketType, repeated: true },
    { no: 3, name: "show_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "ticket_print_status", kind: "enum", T: proto3.getEnumType(TicketPrintStatus) },
    { no: 5, name: "subtotal", kind: "message", T: Money },
    { no: 6, name: "discount", kind: "message", T: Money },
    { no: 7, name: "fee", kind: "message", T: Money },
    { no: 8, name: "refund", kind: "message", T: Money },
    { no: 9, name: "net", kind: "message", T: Money },
    { no: 10, name: "total", kind: "message", T: Money },
    { no: 11, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.SeasonPackageOrderItem
 */
export const SeasonPackageOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.SeasonPackageOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "season_package_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "season_package_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "season_package_image_asset", kind: "message", T: Asset },
    { no: 7, name: "group_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "stat", kind: "message", T: SeasonPackageOrderItemStat },
    { no: 9, name: "google_wallet_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MembershipOrderItem
 */
export const MembershipOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MembershipOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "revenue_type", kind: "enum", T: proto3.getEnumType(RevenueType) },
    { no: 5, name: "membership", kind: "message", T: Membership },
    { no: 6, name: "membership_subscription_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 8, name: "subtotal", kind: "message", T: Money },
    { no: 9, name: "discount", kind: "message", T: Money },
    { no: 10, name: "fee", kind: "message", T: Money },
    { no: 11, name: "refund", kind: "message", T: Money },
    { no: 12, name: "net", kind: "message", T: Money },
    { no: 13, name: "total", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.MerchandiseOrderItem
 */
export const MerchandiseOrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.MerchandiseOrderItem",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "deleted_at", kind: "message", T: Timestamp },
    { no: 4, name: "merchandise_sku_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "merchandise", kind: "message", T: Merchandise },
    { no: 6, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderItemPaymentStatus) },
    { no: 7, name: "fulfillment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(FulfillmentStatus) },
    { no: 9, name: "subtotal", kind: "message", T: Money },
    { no: 10, name: "discount", kind: "message", T: Money },
    { no: 11, name: "fee", kind: "message", T: Money },
    { no: 12, name: "refund", kind: "message", T: Money },
    { no: 13, name: "net", kind: "message", T: Money },
    { no: 14, name: "total", kind: "message", T: Money },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.OrderItem
 */
export const OrderItem = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.OrderItem",
  () => [
    { no: 101, name: "donation_item", kind: "message", T: DonationOrderItem, oneof: "item" },
    { no: 102, name: "ticket_item", kind: "message", T: TicketOrderItem, oneof: "item" },
    { no: 103, name: "season_package_item", kind: "message", T: SeasonPackageOrderItem, oneof: "item" },
    { no: 104, name: "membership_item", kind: "message", T: MembershipOrderItem, oneof: "item" },
    { no: 105, name: "merchandise_item", kind: "message", T: MerchandiseOrderItem, oneof: "item" },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.Order
 */
export const Order = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.Order",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created_at", kind: "message", T: Timestamp },
    { no: 4, name: "sales_channel", kind: "enum", T: proto3.getEnumType(SalesChannel) },
    { no: 5, name: "payment_status", kind: "enum", T: proto3.getEnumType(OrderPaymentStatus) },
    { no: 6, name: "payment_method", kind: "message", T: PaymentMethod },
    { no: 7, name: "order_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "billing_info", kind: "message", T: BillingInfo },
    { no: 9, name: "shipping_info", kind: "message", T: ShippingInfo },
    { no: 10, name: "order_items", kind: "message", T: OrderItem, repeated: true },
    { no: 11, name: "subtotal", kind: "message", T: Money },
    { no: 12, name: "discount", kind: "message", T: Money },
    { no: 13, name: "fee", kind: "message", T: Money },
    { no: 14, name: "refund", kind: "message", T: Money },
    { no: 15, name: "net", kind: "message", T: Money },
    { no: 16, name: "total", kind: "message", T: Money },
    { no: 17, name: "google_wallet_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetOrderRequest
 */
export const GetOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetOrderRequest",
  () => [
    { no: 1, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetOrderResponse
 */
export const GetOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetOrderResponse",
  () => [
    { no: 1, name: "order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetOrderByIDRequest
 */
export const GetOrderByIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetOrderByIDRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.GetOrderByIDResponse
 */
export const GetOrderByIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.GetOrderByIDResponse",
  () => [
    { no: 1, name: "order", kind: "message", T: Order },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListOrdersRequest
 */
export const ListOrdersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListOrdersRequest",
  [],
);

/**
 * @generated from message cuebox.services.consumer.v1.ListOrdersResponse
 */
export const ListOrdersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.ListOrdersResponse",
  () => [
    { no: 1, name: "orders", kind: "message", T: Order, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.DownloadOrderTicketsRequest
 */
export const DownloadOrderTicketsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.DownloadOrderTicketsRequest",
  () => [
    { no: 1, name: "order_uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "ticket_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.consumer.v1.DownloadOrderTicketsResponse
 */
export const DownloadOrderTicketsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.consumer.v1.DownloadOrderTicketsResponse",
  () => [
    { no: 1, name: "ticket_pdf", kind: "message", T: File },
  ],
);

