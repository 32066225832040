import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

const $fg = cssVar('alert-fg');
const $bg = cssVar('alert-bg');

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(alertAnatomy.keys);

const variantCuebox = definePartsStyle(({ colorScheme: c }) => ({
  container: {
    [$fg.variable]: `colors.${c}.500`,
    [$bg.variable]: `colors.${c}.50`,
    borderRadius: 'lg',
    borderWidth: '2px',
    borderColor: $fg.reference,
    flexShrink: 0,
  },
  description: {
    fontWeight: 'medium',
    flexGrow: 1,
    // This is set to 1.5rem in the default theme, which makes it scale poorly with font size.
    // By setting it to a numeric value, it will scale with font size.
    lineHeight: 1.5,
  },
}));

const variantCueboxUnderline = definePartsStyle(({ colorScheme: c }) => ({
  container: {
    width: 'auto',
    maxWidth: '100%',
    [$fg.variable]: `colors.${c}.500`,
    [$bg.variable]: `colors.${c}.50`,
    borderBottomWidth: '2px',
    borderColor: $fg.reference,
    flexShrink: 0,
  },
  description: {
    fontWeight: 'medium',
    flexGrow: 1,
    lineHeight: 1.5,
  },
}));

export const alertTheme = defineMultiStyleConfig({
  variants: {
    cuebox: variantCuebox,
    'cuebox-underline': variantCueboxUnderline,
  },
  defaultProps: {
    variant: 'cuebox',
  },
});
