import 'core-js/actual/array/at';
import 'core-js/actual/array/to-sorted';
import 'core-js/actual/array/with';
import { type PropsWithChildren, useMemo } from 'react';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import { ChakraProvider } from '@chakra-ui/react';
import { ClerkProvider } from '@clerk/nextjs';
import AppProgressBar from '@components/AppProgressBar';
import { GoogleMapsScriptProvider } from '@components/GooglePlacesAutocomplete';
import { toastOptions } from '@components/Toast';
import { IS_DD_ENABLED, isSSR } from '@config/env';
import type { AppPropsWithExtensions } from '@cuebox-types/next';
import { FeatureFlagProvider } from '@hooks/useFeatureFlags';
import {
  ErrorTrackingProvider,
  initDatadogLogging,
} from '@utils/errorTracking';
import { useChakraTheme } from '@utils/theme/chakra';
import { appearance } from '@utils/theme/clerk';

// @ts-expect-error Property 'UrlPattern' does not exist (this is the recommended approach from the polyfill docs)
if (!globalThis.URLPattern) {
  await import('urlpattern-polyfill');
}

// Kick off DD logging and RUM collection.
if (IS_DD_ENABLED) {
  initDatadogLogging();
}

// If pages do not specify a provider then just render child content.
const NoopProvider = ({ children }: PropsWithChildren) => children;

const App = (props: AppPropsWithExtensions) => {
  const { Component, pageProps } = props;

  const PageComponentProvider = useMemo(
    () =>
      typeof Component.getProvider === 'function'
        ? Component.getProvider()
        : NoopProvider,
    [Component],
  );

  const themeOverrides = useMemo(() => {
    return typeof Component.getThemeOverrides === 'function'
      ? Component.getThemeOverrides()
      : undefined;
  }, [Component]);

  const theme = useChakraTheme(themeOverrides);

  return (
    <ClerkProvider {...pageProps} appearance={appearance}>
      <ChakraProvider theme={theme} toastOptions={toastOptions}>
        <ErrorTrackingProvider {...pageProps}>
          <FeatureFlagProvider isSSR={isSSR}>
            <GoogleMapsScriptProvider>
              <PageComponentProvider {...pageProps}>
                <NuqsAdapter>
                  <AppProgressBar />
                  <Component {...pageProps} />
                </NuqsAdapter>
              </PageComponentProvider>
            </GoogleMapsScriptProvider>
          </FeatureFlagProvider>
        </ErrorTrackingProvider>
      </ChakraProvider>
    </ClerkProvider>
  );
};

export default App;
