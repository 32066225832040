// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/permission.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.Permission
 */
export const Permission = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Permission",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.Role
 */
export const Role = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.Role",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "permissions", kind: "message", T: Permission, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPermissionsRequest
 */
export const ListPermissionsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPermissionsRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListPermissionsResponse
 */
export const ListPermissionsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListPermissionsResponse",
  () => [
    { no: 1, name: "permissions", kind: "message", T: Permission, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListRolesRequest
 */
export const ListRolesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListRolesRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListRolesResponse
 */
export const ListRolesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListRolesResponse",
  () => [
    { no: 1, name: "roles", kind: "message", T: Role, repeated: true },
  ],
);

