// @generated by protoc-gen-es v1.10.0 with parameter "target=js+dts"
// @generated from file cuebox/services/staff/v1/staff_member.proto (package cuebox.services.staff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message cuebox.services.staff.v1.StaffMember
 */
export const StaffMember = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.StaffMember",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "role_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.ListStaffMembersRequest
 */
export const ListStaffMembersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListStaffMembersRequest",
  [],
);

/**
 * @generated from message cuebox.services.staff.v1.ListStaffMembersResponse
 */
export const ListStaffMembersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.ListStaffMembersResponse",
  () => [
    { no: 1, name: "staff_members", kind: "message", T: StaffMember, repeated: true },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetStaffMemberRequest
 */
export const GetStaffMemberRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetStaffMemberRequest",
  () => [
    { no: 1, name: "staff_member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.GetStaffMemberResponse
 */
export const GetStaffMemberResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.GetStaffMemberResponse",
  () => [
    { no: 1, name: "staff_member", kind: "message", T: StaffMember },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateStaffMemberRoleRequest
 */
export const UpdateStaffMemberRoleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateStaffMemberRoleRequest",
  () => [
    { no: 1, name: "staff_member_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "role_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message cuebox.services.staff.v1.UpdateStaffMemberRoleResponse
 */
export const UpdateStaffMemberRoleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "cuebox.services.staff.v1.UpdateStaffMemberRoleResponse",
  () => [
    { no: 1, name: "staff_member", kind: "message", T: StaffMember },
  ],
);

