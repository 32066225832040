import {
  addDays,
  addMonths,
  addSeconds,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  format,
  intervalToDuration,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMilliseconds,
  subMonths,
  subYears,
} from 'date-fns';
import { Timestamp as TS } from '@bufbuild/protobuf';
import type { Organization } from '@cuebox-types/auth';
import {
  type Duration,
  type TimeRange,
  TimeRangePreset,
  type Timestamp,
} from '@cuebox-types/dates';
import type { Card } from '@cuebox-types/orders';
import { TZDate } from '@date-fns/tz';
import type { DateValue } from '@internationalized/date';
import { CalendarDate, CalendarDateTime, Time } from '@internationalized/date';
import { logWarn } from './errorTracking/utils/log';

export const UTC = 'UTC';
export const HOURS_IN_DAY = 24;
export const HOURS_IN_WEEK = HOURS_IN_DAY * 7;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const MS_IN_SECOND = 1000;
export const SECONDS_IN_HOUR = SECONDS_IN_MINUTE * MINUTES_IN_HOUR;
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY;

const localeConfig = {
  // This field must be overwritten by the org timezone when the org is initially fetched.
  timezone: '',
  // We will only have en-US for the foreseeable future, but we might as well store it here to match the TZ pattern.
  locale: 'en-US',
};

export const getLoadedTimezone = (): string => {
  const { timezone } = localeConfig;
  if (!timezone) {
    throw new Error(
      'Attempting to get timezone before it has been initialized',
    );
  }
  return timezone;
};

export const getLoadedLocale = (): string => {
  const { locale } = localeConfig;
  if (!locale) {
    throw new Error('Attempting to get locale before it has been initialized');
  }
  return locale;
};

/**
 * This must be initialized with the org timezone on app load.
 */
export const loadTimezoneLocale = (timeZone: string) => {
  localeConfig.timezone = timeZone;
};

/**
 * Converts a proto Timestamp into a Date.
 * Timestamps coming from the server are always UTC, with the Date instance being offset by browser/env timezone.
 */
export const tsToDate = (ts: Timestamp): Date => {
  return new TS(ts).toDate();
};

/**
 * Converts a Date into a proto Timestamp.
 * The Timestamp will have a datetime value in UTC with an offset from the browser/env Date instance.
 */
export const dateToTs = (date: Date): TS => {
  return TS.fromDate(date);
};

/**
 * Convert a proto Timestamp to a TZDate in the specified timezone.
 * Falls back to the browser's Date if the TZDate is incompatible with the browser version (e.g. Safari\<15.4 or Chrome\<95)
 */
export const tsToTzDate = (ts: Timestamp, tz: string): Date => {
  const date = tsToDate(ts);
  const tzDate = new TZDate(date, tz);
  if (Number.isNaN(tzDate.valueOf())) {
    logWarn('Invalid TZDate, falling back to browser Date', {
      timezone: tz,
      date,
    });
    return date;
  }
  return tzDate;
};

/**
 * Creates a new date-fns TZDate from a proto Timestamp in the current org's timezone.
 */
export const tsToOrgTzDate = (ts: Timestamp): Date => {
  return tsToTzDate(ts, getLoadedTimezone());
};

/**
 * Creates a new TZDate at the current time in the org timezone for convenience.
 * Falls back to the browser's Date if the TZDate is incompatible with the browser version (e.g. Safari\<15.4 or Chrome\<95)
 */
export const nowInOrgTz = (): Date => {
  const now = new Date();
  const tzNow = new TZDate(now, getLoadedTimezone());
  if (Number.isNaN(tzNow.valueOf())) {
    logWarn('Invalid TZDate, falling back to browser Date', {
      timezone: getLoadedTimezone(),
      date: now,
    });
    return now;
  }
  return tzNow;
};

/**
 * Splits out the properties of a Date for convenience when creating new timezone dates.
 */
export const pluckDateAttrs = (date: Date) => {
  return {
    year: date.getFullYear(),
    month: date.getMonth(),
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
    millisecond: date.getMilliseconds(),
  };
};

/** Get the calendar day of the provided date at midnight in the provided timezone */
export const getDayInTz = (date: Date, tz: string): Date => {
  const { year, month, day } = pluckDateAttrs(date);
  return new TZDate(year, month, day, tz);
};

/**
 * Get the start of the current calendar day in the org's timezone.
 * The date may be different than the browser's date if the timezone difference is significant.
 */
export const today = (): Date => {
  return startOfDay(TZDate.tz(getLoadedTimezone()));
};

export const roundToNearestStartOfMonth = (ts: Timestamp) => {
  const date = tsToOrgTzDate(ts);

  const startOfCurrentMonth = startOfMonth(date);

  const startOfNextMonth = addMonths(startOfCurrentMonth, 1);

  const diffToCurrent = date.valueOf() - startOfCurrentMonth.valueOf();
  const diffToNext = startOfNextMonth.valueOf() - date.valueOf();

  if (diffToCurrent < diffToNext) {
    return startOfCurrentMonth;
  }

  return startOfNextMonth;
};

/**
 * now + seconds as a timestamp, for use in "expires at" situations.
 * Note: this does not need to be in the org TZ as its mainly used for localStorage expiration or similar timestamps.
 */
export const expiresTimestamp = (seconds: number): number => {
  const expirationDate = addSeconds(new Date(), seconds);
  return expirationDate.getTime();
};

/**
 * Format a date as a string.
 * If a TZDate is passed, it will be formatted in the associated timezone.
 *
 * @example
 * formatDate(new Date()) // returns "Dec 8, 2023"
 * formatDate(null) // returns ""
 * formatDate(undefined) // returns ""
 */
export const formatDate = (date: Date | null | undefined): string => {
  if (!date) {
    return '';
  }

  return date.toLocaleDateString(getLoadedLocale(), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

/**
 * Format a date as a string with only the time.
 * If a TZDate is passed, it will be formatted in the associated timezone.
 *
 * @example
 * formatTime(new Date()) // returns "12:00 PM"
 * formatTime(null) // returns ""
 * formatTime(undefined) // returns ""
 */
export const formatTime = (date: Date | null | undefined): string => {
  if (!date) {
    return '';
  }

  return date.toLocaleTimeString(getLoadedLocale(), {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

/**
 * Format a date as a string with both date and time.
 * If a TZDate is passed, it will be formatted in the associated timezone.
 *
 * @example
 * formatDatetime(new Date()) // returns "Dec 8, 2023 12:00 PM"
 * formatDatetime(null) // returns ""
 * formatDatetime(undefined) // returns ""
 */
export const formatDatetime = (date: Date | null | undefined): string => {
  if (!date) {
    return '';
  }

  const formattedDate = formatDate(date);
  const formattedTime = formatTime(date);

  const formattedDatetime = `${formattedDate} ${formattedTime}`;

  return formattedDatetime;
};

/**
 * Format a timestamp as a date string in the org's timezone.
 *
 * @example
 * formatTSDate(dateToTs(new Date())) // returns "Dec 8, 2023"
 * formatTSDate(undefined) // returns ""
 */
export const formatTSDate = (
  ts: Timestamp | undefined,
  timezone?: string,
): string => {
  if (!ts) {
    return '';
  }

  const realTimezone = timezone ?? getLoadedTimezone();

  const orgTzDate = tsToTzDate(ts, realTimezone);
  return formatDate(orgTzDate);
};

/**
 * Format a timestamp as a datetime string in the org's timezone.
 *
 * @example
 * formatTSDatetime(dateToTs(new Date())) // returns "Dec 8, 2023 12:00 PM"
 * formatTSDatetime(undefined) // returns ""
 */
export const formatTSDatetime = (
  ts: Timestamp | undefined,
  timezone?: string,
): string => {
  if (!ts) {
    return '';
  }

  const realTimezone = timezone ?? getLoadedTimezone();

  const orgTzDate = tsToTzDate(ts, realTimezone);
  return formatDatetime(orgTzDate);
};

/**
 * Format a timestamp in the user's browser timezone
 *
 * @example
 * formatLocalTSDate(dateToTs(new Date())) // returns "Dec 8, 2023"
 * formatLocalTSDate(undefined) // returns ""
 */
export const formatLocalTSDate = (ts: Timestamp | undefined): string => {
  if (!ts) {
    return '';
  }

  const date = tsToDate(ts);
  return formatDate(date);
};

/** Determine whether a credit/debit card has expired already */
export const getHasCardExpired = (card: Card | null | undefined): boolean => {
  if (!card) {
    return false;
  }

  const now = today();
  const expirationDate = new Date(
    card.expirationYear,
    card.expirationMonth - 1,
  );

  return expirationDate.getTime() < now.getTime();
};

export const formatExpirationDate = (
  month: number,
  year: number,
  addSpacing?: boolean,
): string => {
  const monthStr = month.toString().padStart(2, '0');
  const yearStr = year.toString().slice(-2);

  return [monthStr, '/', yearStr].join(addSpacing ? ' ' : '');
};

export const padZeros = (v: number | string, n = 2) => `${v}`.padStart(n, '0');

/** Format a date for use with a type="time" input field */
export const formatNativeTimeValue = (date: Date): string => {
  const hours = padZeros(date.getHours());
  const minutes = padZeros(date.getMinutes());

  return `${hours}:${minutes}`;
};

export type ParsedTime = {
  hours: number;
  minutes: number;
};

export const parseNativeTimeValue = (time: string): ParsedTime => {
  if (!time) {
    return { hours: 0, minutes: 0 };
  }

  const [hoursStr, minutesStr] = time.split(':');

  return {
    hours: parseInt(hoursStr, 10),
    minutes: parseInt(minutesStr, 10),
  };
};

/**
 * Extracts the number of seconds in a time string.
 *
 * @param timeStr - A string in the format "HH:MM"
 */
export const getSecondsFromTimeStr = (timeStr: string): number => {
  const { hours, minutes } = parseNativeTimeValue(timeStr);

  const hoursSeconds = hours * SECONDS_IN_HOUR;
  const minutesSeconds = minutes * SECONDS_IN_MINUTE;

  return hoursSeconds + minutesSeconds;
};

/** Format a date for use with a type="date" input field */
export const formatNativeDatePickerValue = (
  date: Date | null | undefined,
): string => {
  if (!date) {
    return '';
  }

  return format(date, 'yyyy-MM-dd');
};

/**
 * Parse a date string from a native date picker input.
 *
 * @param dateString - A string in the format "YYYY-MM-DD"
 * @returns A Date object representing the parsed date in the local timezone, or null if the string is empty
 */
export const parseNativeDatePickerValue = (dateString: string): Date | null => {
  // The native date picker returns an empty string if an invalid date is entered
  if (!dateString) {
    return null;
  }

  const [year, month, day] = dateString
    .split('-')
    .map((datePart) => parseInt(datePart, 10));

  return new Date(year, month - 1, day);
};

export const hoursToDuration = (hours: number | undefined): Duration => ({
  nanos: 0,
  seconds: BigInt((hours ?? 0) * SECONDS_IN_HOUR),
});

export const minutesToDuration = (minutes: number | undefined): Duration => ({
  nanos: 0,
  seconds: BigInt((minutes ?? 0) * SECONDS_IN_MINUTE),
});

export const durationToHours = (dur: Duration | undefined): number => {
  if (!dur) {
    return 0;
  }

  return Number(dur.seconds) / SECONDS_IN_HOUR;
};

export const durationToMinutes = (dur: Duration | undefined): number => {
  if (!dur) {
    return 0;
  }

  return Number(dur.seconds) / SECONDS_IN_MINUTE;
};

export const formatDuration = (dur: Duration): string => {
  const { days, hours, minutes } = intervalToDuration({
    start: 0,
    end: Number(dur.seconds) * MS_IN_SECOND,
  });

  const res = [
    days ? `${days} days` : '',
    hours ? `${hours} hr` : '',
    minutes ? `${padZeros(minutes)} min` : '',
  ];

  return res.filter(Boolean).join(' ');
};

/**
 * Display formatter for showtime start time.
 * Note: all showtime display on both the staff and consumer side should be offset by the org timezone.
 */
export const formatShowtimeStartTime = (
  ts: Timestamp | undefined,
  timezone: string | undefined,
  shouldIncludeTimezone = false,
): string => {
  if (!ts || !timezone) {
    return '';
  }

  const tzDate = tsToTzDate(ts, timezone);

  return tzDate.toLocaleTimeString(getLoadedLocale(), {
    timeZone: timezone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: shouldIncludeTimezone ? 'short' : undefined,
  });
};

export type FormatShowtimeDateOpts = {
  /**
   * If passed, the year will be included automatically and the date
   * will be returned with the format "MM/DD/YYYY".
   */
  shouldUseNumericDate?: boolean;
  shouldIncludeYear?: boolean;
  shouldIncludeTime?: boolean;
  shouldIncludeTimezone?: boolean;
  shouldIncludeWeekday?: boolean;
  /** The string that separates the date and the time */
  separator?: string;
};

/**
 * Display formatter for showtime dates and times.
 * Note: all showtime display on both the staff and consumer side should be offset by the org timezone.
 */
export const formatShowtimeDate = (
  ts: Timestamp | undefined,
  timezone: string | undefined,
  opts?: FormatShowtimeDateOpts,
): string => {
  if (!ts || !timezone) {
    return '';
  }

  const {
    shouldUseNumericDate,
    shouldIncludeYear,
    shouldIncludeTime,
    shouldIncludeTimezone,
    shouldIncludeWeekday,
    separator,
  }: FormatShowtimeDateOpts = {
    shouldUseNumericDate: false,
    shouldIncludeYear: false,
    shouldIncludeTime: false,
    shouldIncludeTimezone: false,
    shouldIncludeWeekday: true,
    separator: ', ',
    ...opts,
  };

  const dateParts: string[] = [];

  const tzDate = tsToTzDate(ts, timezone);

  const dateFormatOpts: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    timeZone: timezone,
  };

  if (shouldUseNumericDate) {
    dateFormatOpts.month = 'numeric';
    dateFormatOpts.year = 'numeric';
  } else {
    dateFormatOpts.month = 'short';

    if (shouldIncludeWeekday) {
      dateFormatOpts.weekday = 'short';
    }
    if (shouldIncludeYear) {
      dateFormatOpts.year = 'numeric';
    }
  }

  dateParts.push(tzDate.toLocaleDateString(getLoadedLocale(), dateFormatOpts));

  if (shouldIncludeTime) {
    dateParts.push(
      formatShowtimeStartTime(ts, timezone, shouldIncludeTimezone),
    );
  }

  return dateParts.join(separator);
};

export const timestampSort = (
  tsA: Timestamp | undefined,
  tsB: Timestamp | undefined,
  isDescending = false,
): number => {
  if (!tsA || !tsB) {
    if (!tsA && !tsB) {
      return 0;
    }

    return tsA ? -1 : 1;
  }

  const dateA = tsToDate(tsA);
  const dateB = tsToDate(tsB);

  if (isDescending) {
    return dateB.valueOf() - dateA.valueOf();
  }
  return dateA.valueOf() - dateB.valueOf();
};

/**
 * A comparator function for sorting timestamps in ascending order.
 */
export const timestampSortAsc = (
  tsA: Timestamp | undefined,
  tsB: Timestamp | undefined,
): number => {
  return timestampSort(tsA, tsB);
};

/**
 * A comparator function for sorting timestamps in descending order.
 */
export const timestampSortDesc = (
  tsA: Timestamp | undefined,
  tsB: Timestamp | undefined,
): number => {
  return timestampSort(tsA, tsB, true);
};

export const getAreSameYear = (
  tsA: Timestamp | undefined,
  tsB: Timestamp | undefined,
): boolean => {
  if (!tsA || !tsB) {
    return false;
  }

  return tsToOrgTzDate(tsA).getFullYear() === tsToOrgTzDate(tsB).getFullYear();
};

/**
 * Get the start date of the current fiscal year for the org.
 *
 * @param fiscalYearStartTs - The timestamp of the start of the fiscal year pulled from the app context
 * @returns A Date object representing the start of the current fiscal year
 */
export const getCurrentFiscalYearStartDate = (
  fiscalYearStartTs: Timestamp,
): Date => {
  let fiscalYearStartDate = tsToOrgTzDate(fiscalYearStartTs);

  const currentYear = today().getFullYear();
  fiscalYearStartDate.setFullYear(currentYear);

  if (fiscalYearStartDate.valueOf() > today().valueOf()) {
    fiscalYearStartDate = subYears(fiscalYearStartDate, 1);
  }

  return fiscalYearStartDate;
};

export const getFiscalYearToDateTimeRange = (
  fiscalYearStartDate: Timestamp,
): Required<TimeRange> => {
  const currentFiscalYearStartDate =
    getCurrentFiscalYearStartDate(fiscalYearStartDate);
  const endOfToday = endOfDay(today());

  return {
    start: dateToTs(currentFiscalYearStartDate),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.FISCAL_YEAR_TO_DATE,
  };
};

export const getLastFiscalYearTimeRange = (
  fiscalYearStartDate: Timestamp,
): Required<TimeRange> => {
  const currentFiscalYearStartDate =
    getCurrentFiscalYearStartDate(fiscalYearStartDate);
  const lastFiscalYearStartDate = subYears(currentFiscalYearStartDate, 1);
  const lastFiscalYearEndDate = subMilliseconds(currentFiscalYearStartDate, 1);

  return {
    start: dateToTs(lastFiscalYearStartDate),
    end: dateToTs(lastFiscalYearEndDate),
    preset: TimeRangePreset.LAST_FISCAL_YEAR,
  };
};

export const getMonthToDateTimeRange = (): Required<TimeRange> => {
  const startOfCurrentMonth = startOfMonth(today());
  const endOfToday = endOfDay(today());

  return {
    start: dateToTs(startOfCurrentMonth),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.MONTH_TO_DATE,
  };
};

export const getLast60DaysTimeRange = (): Required<TimeRange> => {
  const endOfToday = endOfDay(today());
  const sixtyDaysAgo = subDays(today(), 60);

  return {
    start: dateToTs(sixtyDaysAgo),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.ABSOLUTE,
  };
};

export const getTodayTimeRange = (): Required<TimeRange> => {
  const startOfToday = today();
  const endOfToday = endOfDay(today());

  return {
    start: dateToTs(startOfToday),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.TODAY,
  };
};

/**
 * Calculates the time range for the upcoming or current weekend, starting from today, with today as the minimum date.
 *
 * - If today is Sunday, the range will include only today.
 * - If today is Saturday, the range will include today and the following day.
 * - If today is any other day, the range will start from the next Saturday and end on the following Sunday.
 */
export const getThisWeekendTimeRange = (): Required<TimeRange> => {
  const startOfToday = today();
  let startOfCurrentWeekend: Date;
  let endOfCurrentWeekend: Date;

  if (startOfToday.getDay() === 0) {
    // If today is Sunday, the weekend is today only
    startOfCurrentWeekend = new Date(startOfToday);
    endOfCurrentWeekend = endOfDay(startOfToday);
  } else {
    // Calculate the number of days to add to reach the next Saturday
    const daysUntilSaturday = 6 - startOfToday.getDay();
    startOfCurrentWeekend = addDays(startOfToday, daysUntilSaturday);

    // Set the end of the weekend to the next day (Sunday)
    endOfCurrentWeekend = endOfDay(addDays(startOfCurrentWeekend, 1));
  }

  return {
    start: dateToTs(startOfCurrentWeekend),
    end: dateToTs(endOfCurrentWeekend),
    preset: TimeRangePreset.THIS_WEEKEND,
  };
};

/**
 * Get a time range representing the current month, with today as the minimum date.
 */
export const getThisMonthTimeRange = (): Required<TimeRange> => {
  const startOfToday = startOfDay(today());
  const endOfThisMonth = endOfMonth(today());

  return {
    start: dateToTs(startOfToday),
    end: dateToTs(endOfThisMonth),
    preset: TimeRangePreset.THIS_MONTH,
  };
};

export const getAllTimeRange = (): Required<TimeRange> => {
  const startDate = new TZDate(1900, 0, 1, getLoadedTimezone());
  const endOfToday = endOfDay(today());

  return {
    start: dateToTs(startDate),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.ALL_TIME,
  };
};

export const getPast12MonthsTimeRange = (): Required<TimeRange> => {
  const twelveMonthsAgo = subMonths(today(), 12);
  const endOfToday = endOfDay(today());

  return {
    start: dateToTs(twelveMonthsAgo),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.LAST_TWELVE_MONTHS_TO_DATE,
  };
};

export const getYearToDateTimeRange = (): Required<TimeRange> => {
  const startOfCurrentYear = startOfYear(today());
  const endOfToday = endOfDay(today());

  return {
    start: dateToTs(startOfCurrentYear),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.YEAR_TO_DATE,
  };
};

export const getLastYearTimeRange = (): Required<TimeRange> => {
  const startOfLastYear = startOfYear(subYears(today(), 1));
  const endOfLastYear = endOfYear(startOfLastYear);

  return {
    start: dateToTs(startOfLastYear),
    end: dateToTs(endOfLastYear),
    preset: TimeRangePreset.LAST_YEAR,
  };
};

export const getLastMonthTimeRange = (): Required<TimeRange> => {
  const startOfLastMonth = startOfMonth(subMonths(today(), 1));
  const endOfLastMonth = endOfMonth(startOfLastMonth);

  return {
    start: dateToTs(startOfLastMonth),
    end: dateToTs(endOfLastMonth),
    preset: TimeRangePreset.LAST_MONTH,
  };
};

/**
 * Given a TimeRange with a start and end date, this function will return a new TimeRange
 * that has the start and end dates normalized to the start and end of the day in the org's timezone.
 */
export const getNormalizedTimeRange = (timeRange: TimeRange): TimeRange => {
  return {
    start: timeRange.start
      ? dateToTs(startOfDay(tsToOrgTzDate(timeRange.start)))
      : undefined,
    end: timeRange.end
      ? dateToTs(endOfDay(tsToOrgTzDate(timeRange.end)))
      : undefined,
    preset: timeRange.preset,
  };
};

export const getLast30DaysTimeRange = (): Required<TimeRange> => {
  const endOfToday = endOfDay(today());
  const thirtyDaysAgo = subDays(today(), 30);

  return {
    start: dateToTs(thirtyDaysAgo),
    end: dateToTs(endOfToday),
    preset: TimeRangePreset.LAST_THIRTY_DAYS_TO_DATE,
  };
};

/**
 * This function is used to get the current version of a TimeRange, if it is based on a preset.
 */
export const getUpdatedTimeRangeFromPreset = (
  timeRange: TimeRange | undefined,
  fiscalYearStartDate: Timestamp,
): TimeRange | undefined => {
  if (!timeRange) {
    return undefined;
  }

  switch (timeRange.preset) {
    case TimeRangePreset.FISCAL_YEAR_TO_DATE:
      return getFiscalYearToDateTimeRange(fiscalYearStartDate);
    case TimeRangePreset.LAST_FISCAL_YEAR:
      return getLastFiscalYearTimeRange(fiscalYearStartDate);
    case TimeRangePreset.MONTH_TO_DATE:
      return getMonthToDateTimeRange();
    case TimeRangePreset.ALL_TIME:
      return getAllTimeRange();
    case TimeRangePreset.LAST_TWELVE_MONTHS_TO_DATE:
      return getPast12MonthsTimeRange();
    case TimeRangePreset.YEAR_TO_DATE:
      return getYearToDateTimeRange();
    case TimeRangePreset.LAST_YEAR:
      return getLastYearTimeRange();
    case TimeRangePreset.LAST_MONTH:
      return getLastMonthTimeRange();
    case TimeRangePreset.LAST_THIRTY_DAYS_TO_DATE:
      return getLast30DaysTimeRange();
    case TimeRangePreset.TODAY:
      return getTodayTimeRange();
    case TimeRangePreset.THIS_WEEKEND:
      return getThisWeekendTimeRange();
    case TimeRangePreset.THIS_MONTH:
      return getThisMonthTimeRange();

    case TimeRangePreset.ABSOLUTE:
    case TimeRangePreset.UNSPECIFIED:
    default:
      return timeRange;
  }
};

export const formatSimplifiedDate = (
  ts: Timestamp | undefined,
  includeYear = false,
): string => {
  if (!ts) {
    return '';
  }

  return tsToOrgTzDate(ts).toLocaleDateString(getLoadedLocale(), {
    day: 'numeric',
    month: 'short',
    year: includeYear ? 'numeric' : undefined,
  });
};

export const getAreCalendarDatesEqual = (
  dateA: Date | undefined,
  dateB: Date | undefined,
) => {
  if (!dateA || !dateB) {
    return false;
  }

  const dayA = getDayInTz(dateA, getLoadedTimezone());
  const dayB = getDayInTz(dateB, getLoadedTimezone());

  return dayA.valueOf() === dayB.valueOf();
};

/**
 * Check if two dates are on the same day, in the local timezone.
 */
export const getAreTzCalendarDatesEqual = (
  tsA: Timestamp | undefined,
  tsB: Timestamp | undefined,
) => {
  if (!tsA || !tsB) {
    return false;
  }

  const dateTimeA = tsToOrgTzDate(tsA);
  const dateTimeB = tsToOrgTzDate(tsB);

  return startOfDay(dateTimeA).getTime() === startOfDay(dateTimeB).getTime();
};

/**
 * If the first and last dates are the same, we only want to show one
 * If the first and last dates are on the same year, we only want to show the year for one
 */
export const getFormattedDateRange = (
  startDate: Timestamp | undefined,
  endDate: Timestamp | undefined,
  shouldIncludeYear = true,
) => {
  const areSameDate = getAreCalendarDatesEqual(
    startDate ? tsToOrgTzDate(startDate) : undefined,
    endDate ? tsToOrgTzDate(endDate) : undefined,
  );
  const areSameYear = getAreSameYear(startDate, endDate);

  const startDateStr =
    !!startDate && !areSameDate
      ? formatSimplifiedDate(startDate, !areSameYear && shouldIncludeYear)
      : null;

  const endDateStr = endDate
    ? formatSimplifiedDate(endDate, shouldIncludeYear)
    : null;

  return [startDateStr, endDateStr].filter(Boolean).join(' - ');
};

/**
 * Simple helper which returns a local datetime for use in AG grid export filenames.
 * This doesn't need to be in the org's timezone, as it's really just meant to be
 * the current date for the user.
 */
export const getFilenameDate = (date = new Date()) => {
  const year = date.getFullYear();
  const month = padZeros(date.getMonth() + 1);
  const day = padZeros(date.getDate());

  return `${year}-${month}-${day}`;
};

/**
 * Helper that takes in start and end date timestamps and returns a string that represents the date range.
 * Ex. 'Dec 08, 2023 - Dec 07, 2023'
 */
export const getFormattedDateRangeFromStartAndEndTimestamps = (
  startTimestamp: Timestamp | undefined,
  endTimestamp: Timestamp | undefined,
) => {
  const opts: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  let formattedStartDate = '';
  if (startTimestamp) {
    formattedStartDate = tsToOrgTzDate(startTimestamp).toLocaleString(
      getLoadedLocale(),
      opts,
    );
  }

  let formattedEndDate = '';
  if (endTimestamp) {
    formattedEndDate = tsToOrgTzDate(endTimestamp).toLocaleString(
      getLoadedLocale(),
      opts,
    );
  }

  return [formattedStartDate, formattedEndDate].filter(Boolean).join(' - ');
};

export const timeRangeLabels: Record<TimeRangePreset, string> = {
  [TimeRangePreset.UNSPECIFIED]: '',
  [TimeRangePreset.ABSOLUTE]: '',
  [TimeRangePreset.ALL_TIME]: 'All Time',
  [TimeRangePreset.FISCAL_YEAR_TO_DATE]: 'Fiscal Year to Date',
  [TimeRangePreset.LAST_FISCAL_YEAR]: 'Last Fiscal Year',
  [TimeRangePreset.LAST_MONTH]: 'Last Month',
  [TimeRangePreset.LAST_TWELVE_MONTHS_TO_DATE]: 'Last 12 Months to Date',
  [TimeRangePreset.LAST_YEAR]: 'Last Year',
  [TimeRangePreset.MONTH_TO_DATE]: 'Month to Date',
  [TimeRangePreset.YEAR_TO_DATE]: 'Year to Date',
  [TimeRangePreset.LAST_THIRTY_DAYS_TO_DATE]: 'Last 30 Days to Date',
  [TimeRangePreset.TODAY]: 'Today',
  [TimeRangePreset.THIS_WEEKEND]: 'This Weekend',
  [TimeRangePreset.THIS_MONTH]: 'This Month',
};

export const formatTimeRange = (
  timeRange: TimeRange | undefined,
  timezone?: string,
): string => {
  if (!timeRange) {
    return '';
  }

  const { start, end } = timeRange;
  if (!start && !end) {
    return '';
  }

  const label = timeRangeLabels[timeRange.preset];
  if (label) {
    return label;
  }

  const formattedStart = formatTSDate(start, timezone);
  const formattedEnd = formatTSDate(end, timezone);

  if (!formattedEnd) {
    return `After ${formattedStart}`;
  }

  if (!formattedStart) {
    return `Before ${formattedEnd}`;
  }

  return [formattedStart, formattedEnd].join(' - ');
};

export const getFiscalYearLabel = (
  org: Organization | undefined,
  yearOffset = 0,
): string => {
  if (!org) {
    return '';
  }

  if (!org.fiscalYearStartDate) {
    // This shouldn't happen, but in case it does we are returning something reasonable.
    switch (yearOffset) {
      case 0:
        return 'Current FY';
      case 1:
        return 'Previous FY';
      default:
        return 'Earlier FY';
    }
  }

  const currentFiscalYearStartDate = getCurrentFiscalYearStartDate(
    org.fiscalYearStartDate,
  );

  const currentFiscalYearEndYear = addYears(
    currentFiscalYearStartDate,
    1,
  ).getFullYear();

  return `FY${(currentFiscalYearEndYear - yearOffset).toString().substring(2)}`;
};

export const endOfDayTime = new Time(23, 59, 59, 999);

export const dateValueToTimestamp = (
  dateValue: DateValue,
  timezone?: string,
): Timestamp => {
  const realTimezone = timezone || getLoadedTimezone();

  const date = dateValue.toDate(realTimezone);

  return dateToTs(date);
};

export const timestampToCalendarDate = (
  timestamp: Timestamp,
  timezone?: string,
): CalendarDate => {
  const realTimezone = timezone || getLoadedTimezone();

  const date = tsToTzDate(timestamp, realTimezone);

  return new CalendarDate(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
  );
};

export const timestampToCalendarDateTime = (
  timestamp: Timestamp,
  timezone?: string,
): CalendarDateTime => {
  const realTimezone = timezone || getLoadedTimezone();

  const date = tsToTzDate(timestamp, realTimezone);
  return new CalendarDateTime(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  );
};
