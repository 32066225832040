import { useCallback, useContext, useMemo } from 'react';
import useSWR, { type SWRConfiguration } from 'swr';
import type { Message, ServiceType } from '@bufbuild/protobuf';
import { useToast } from '@chakra-ui/react';
import { logWarn } from '@utils/errorTracking/utils/log';
import { getErrorMessage, isNotFoundError } from '@utils/errors';
import useGenerateKey from '../hooks/useGenerateKey';
import type {
  BaseContextApi,
  FetcherOrMutatorCallback,
  GetMethod,
  Headers,
  MessageProps,
  ServiceArgs,
} from '../types';

export interface ConnectFetcherArgs<
  Service extends ServiceType,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
> {
  fetcher: FetcherOrMutatorCallback<Service, Params, FRes>;
  method: GetMethod<Service>;
}

export type UseServiceProps<Params extends Message<Params>, FRes> = {
  headers?: Headers;
  params?: MessageProps<Params>;
  shouldFetch?: boolean;
  useDefaultErrorHandling?: boolean;
} & Pick<
  SWRConfiguration<FRes>,
  'onError' | 'onSuccess' | 'keepPreviousData' | 'refreshInterval'
>;

const DEFAULT_FOCUS_THROTTLE_INTERVAL = 30 * 1000;

const createConnectFetcher = <
  Service extends ServiceType,
  ContextApi extends BaseContextApi<Service>,
  Params extends Message<Params>,
  FRes extends Message<FRes>,
>(
  args: ConnectFetcherArgs<Service, Params, FRes>,
  serviceArgs: ServiceArgs<Service, ContextApi>,
) => {
  const { context: serviceContext, service } = serviceArgs;
  const { fetcher: fetcherFn, method: getMethod } = args;

  const useFetcher = (props?: UseServiceProps<Params, FRes>) => {
    const {
      headers = {},
      params = {},
      shouldFetch = true,
      useDefaultErrorHandling = true,
      keepPreviousData = false,
      refreshInterval = 0,
      ...swrProps
    } = props || {};

    const { client } = useContext<ContextApi>(serviceContext);
    const toast = useToast();

    const { key, deserializeKey } = useGenerateKey<Service, Params, FRes>({
      client,
      headers,
      method: getMethod(service),
      params,
      service,
      shouldFetch,
    });

    const fetcher = useMemo(
      () => async (stringKey: string) => {
        try {
          return await fetcherFn({
            ...deserializeKey(stringKey),
            params,
          });
        } catch (err) {
          if (err instanceof Error) {
            logWarn('connectFetcher error', JSON.parse(stringKey), err);
          } else {
            logWarn('connectFetcher error', JSON.parse(stringKey));
          }

          // For not found errors we always want to throw so we can handle in components.
          if (isNotFoundError(err)) {
            throw err;
          }

          if (useDefaultErrorHandling) {
            toast({
              status: 'warning',
              title: getErrorMessage(
                err,
                'Data was not loaded. Please try again.',
              ),
            });
          } else {
            throw err;
          }
        }
      },
      [deserializeKey, params, useDefaultErrorHandling, toast],
    );

    const { mutate, ...swrRes } = useSWR<
      FRes | undefined,
      Error,
      Record<string, unknown>
    >(key, fetcher, {
      // We're bumping up the focus throttle and removing these intervals to prevent SWR from refetching the same resource too often.
      // In practice this causes screens and components to update at inopportune times, such as when the user is typing in a form.
      // Since we manually revalidate after creating or saving items (and when loading screens), this does not affect the user potentially seeing stale data.
      dedupingInterval: 0,
      focusThrottleInterval: DEFAULT_FOCUS_THROTTLE_INTERVAL,
      refreshInterval,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenHidden: false,
      shouldRetryOnError: false,
      keepPreviousData,
      ...swrProps,
    });

    // Calling mutate without any params is functionally equivalent to asking for a revalidation.
    // So we can use this for cases where we need to perform a related action somewhere else and manually update this query.
    const refetch = useCallback(() => {
      return mutate();
    }, [mutate]);

    return {
      ...swrRes,
      mutate,
      refetch,
    };
  };

  return { useFetcher };
};

export default createConnectFetcher;
