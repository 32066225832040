import { defineStyle, defineStyleConfig } from '@chakra-ui/react';
import type { StyleFunctionProps } from '@chakra-ui/theme-tools';

const buttonBaseStyle = defineStyle({
  borderRadius: 8,
  '@media print': {
    display: 'none',
  },
  lineHeight: 1,
});

const buttonVariantCuebox = defineStyle({
  bg: 'primary.base',
  fontWeight: 600,
  textColor: 'white',
  _hover: {
    bg: 'primary.base',
    _disabled: {
      bg: 'primary.disabled',
    },
  },
  _active: {
    bg: 'primary.active',
    _disabled: {
      bg: 'primary.disabled',
    },
  },
  _disabled: {
    bg: 'primary.disabled',
    opacity: 1,
    textColor: 'white',
  },
});

const buttonVariantOutline = defineStyle({
  borderColor: 'secondary.base',
  fontWeight: 500,
  textColor: 'text.base',
  _hover: {
    bg: 'inherit',
  },
  _active: {
    bg: 'secondary.active',
  },
  _disabled: {
    opacity: 1,
    textColor: 'secondary.disabled',
  },
});

/** A slight variant of the normal outline that includes a filled in background, for use on top of non-white containers */
const buttonVariantFilledOutline = defineStyle({
  color: 'text.base',
  fontWeight: 500,
  borderWidth: '1px',
  borderColor: 'secondary.base',
  bg: 'white',
  _hover: {
    bg: 'gray.50',
    _disabled: {
      bg: 'white',
    },
  },
  _active: {
    bg: 'gray.100',
    _disabled: {
      bg: 'white',
    },
  },
  _disabled: {
    opacity: 1,
    textColor: 'secondary.disabled',
  },
  // This is the official way chakra handles overlapping borders for button groups
  // https://github.com/chakra-ui/chakra-ui/blob/v2/packages/theme/src/components/button.ts#L60-L63
  '.chakra-button__group[data-attached][data-orientation=horizontal] > &:not(:last-of-type)':
    { marginEnd: '-1px' },
  '.chakra-button__group[data-attached][data-orientation=vertical] > &:not(:last-of-type)':
    { marginBottom: '-1px' },
});

const buttonVariantDanger = defineStyle({
  bg: 'error',
  fontWeight: 500,
  textColor: 'white',
  _active: {
    bg: 'red.600',
  },
  _disabled: {
    bg: 'error',
    _hover: {
      bg: 'error !important',
    },
    _active: {
      bg: 'error',
    },
  },
});

const buttonVariantDangerGhost = defineStyle((props: StyleFunctionProps) => {
  const hasIcon = !!props.leftIcon || !!props.rightIcon;

  return {
    fontWeight: 500,
    px: hasIcon ? 3 : 4,
    textColor: 'error',
    _active: {
      opacity: 0.75,
    },
  };
});

const buttonVariantDangerOutline = defineStyle((props: StyleFunctionProps) => {
  const hasIcon = !!props.leftIcon || !!props.rightIcon;

  return {
    border: '1px solid',
    borderColor: 'secondary.base',
    fontWeight: 500,
    px: hasIcon ? 3 : 4,
    textColor: 'error',
    _active: {
      opacity: 0.75,
    },
  };
});

const buttonVariantTag = defineStyle(({ colorScheme }) => ({
  borderWidth: '1px',
  borderRadius: 'md',
  backgroundColor: 'white',
  borderColor: 'dark.400',
  color: 'dark.100',
  fontWeight: 'medium',
  paddingX: 2.5,
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: 7,
  transitionDuration: 'normal',
  transitionProperty: 'border-color, box-shadow',
  cursor: 'pointer',
  fontSize: 'sm',
  _checked: {
    shadow: 'sm',
    borderColor: `${colorScheme}.100`,
    backgroundColor: `${colorScheme}.50`,
    color: `${colorScheme}.500`,
  },
}));

export const buttonTheme = defineStyleConfig({
  baseStyle: buttonBaseStyle,
  variants: {
    cuebox: buttonVariantCuebox,
    danger: buttonVariantDanger,
    dangerGhost: buttonVariantDangerGhost,
    dangerOutline: buttonVariantDangerOutline,
    outline: buttonVariantOutline,
    'filled-outline': buttonVariantFilledOutline,
    tag: buttonVariantTag,
  },
  defaultProps: {
    variant: 'cuebox',
  },
});
